import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { SportGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';

import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// SPORT
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
// ----------------------------------------------------------------------

export const sportsRoutes = [
  {
    path: 'sports',
    element: (
      <SportGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </SportGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      {
        path: ':sportId',
        element: <IndexPage />,
        children: [
          {
            path: ':countryId',
            element: <IndexPage />,
            children: [
              {
                path: ':leagueId',
                element: <IndexPage />,
              },
            ],
          },
          {
            path: 'event/:matchId',
            element: <IndexPage />,
          },
        ],
      },
      {
        path: 'search/:match_name',
        element: <IndexPage />,
      },
    ],
  },
];
