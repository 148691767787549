// slices/match.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import { AppThunk } from '../store.types';

interface MatchState {
  isLoading: boolean;
  isLoadingBtnLM: boolean;
  error: string | null;
  matches: any;
  matches_count: number;
  matchDetail: any | null;
}

const initialState: MatchState = {
  isLoading: false,
  isLoadingBtnLM: false,
  error: null,
  matches: [],
  matches_count: 0,
  matchDetail: null,
};

const slice = createSlice({
  name: 'match',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    startLoadingBtnLM(state) {
      state.isLoadingBtnLM = true;
    },
    hasError(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.isLoadingBtnLM = false;
      state.error = action.payload;
    },
    getMatchsSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.isLoadingBtnLM = false;
      state.matches = action.payload.results;
      state.matches_count = action.payload.count;
    },
    getMatchsPaginateSuccess(state, action: PayloadAction<any>) {
      state.matches = [...state.matches, ...action.payload.results];
      state.matches_count += action.payload.count;
      state.isLoadingBtnLM = false;
    },
    getMatchDetailSuccess(state, action: PayloadAction<any[] | null>) {
      state.matchDetail = action.payload;
    },
    updateOddBet: (state, action: PayloadAction<any>) => {
      const { new_odd } = action.payload;
      if (!new_odd) {
        return;
      }
      state.matches = state.matches?.map((match: any) =>
        match?.odd && match.odd.id === new_odd.id ? { ...match, odd: new_odd } : match
      );
    },
    updateOddBetForMatchDetail: (state, action: PayloadAction<any>) => {
      const { new_odd } = action.payload;
      if (!new_odd) {
        return;
      }
      if (state.matchDetail) {
        state.matchDetail.odds = state.matchDetail?.odds.map((odd: any) =>
          odd?.id === new_odd?.id ? { ...odd, values: new_odd.values } : odd
        );
      }
    },
    updateOddBetForMatchDetailLive: (state, action: PayloadAction<any>) => {
      const { new_odd } = action.payload;
      if (!new_odd) {
        return;
      }
      if (state.matchDetail) {
        state.matchDetail.odds = state.matchDetail?.odds.map((odd: any) =>
          odd?.id === new_odd?.id ? { ...odd, values: new_odd.values } : odd
        );
      }
    },
    updateMatchLive(state, action: PayloadAction<any>) {
      if (!action.payload) {
        return;
      }
      const { match_id, goals, status_live, status_live_seconds } = action.payload;
      state.matches = state.matches?.map((match: any) =>
        match?.id === match_id ? { ...match, goals, status_live, status_live_seconds } : match
      );
      if (state.matchDetail && state.matchDetail?.id === match_id) {
        state.matchDetail = { ...state.matchDetail, goals, status_live, status_live_seconds };
      }
    },
  },
});

export const {
  startLoading,
  startLoadingBtnLM,
  hasError,
  getMatchsSuccess,
  getMatchsPaginateSuccess,
  getMatchDetailSuccess,
  updateOddBet,
  updateOddBetForMatchDetail,
  updateOddBetForMatchDetailLive,
  updateMatchLive,
} = slice.actions;

export default slice.reducer;

export const getMatchs =
  (
    sportId: string | undefined,
    // countryId: string | undefined,
    leagueId: string | undefined,
    match_name: string | undefined,
    page: number | undefined,
    pageSize: number | undefined
  ): AppThunk =>
  async (dispatch) => {
    dispatch(startLoading());
    try {
      let queryString = '';
      if (match_name) {
        queryString = `?name=${match_name}`;
      } else {
        const queryParams = [];
        if (sportId) queryParams.push(`sport=${sportId}`);
        // if (countryId && countryId !== '-1') queryParams.push(`country=${countryId}`);
        if (leagueId) queryParams.push(`league=${leagueId}`);
        if (page) queryParams.push(`page=${page}`);
        if (pageSize) queryParams.push(`page_size=${pageSize}`);
        queryString = queryParams.length ? `?${queryParams.join('&')}` : '';
      }

      const response = await axios.get(`/api/v1/sport/matches${queryString}`);
      dispatch(getMatchsSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error.toString()));
    }
  };

export const getMatchPaginate = async (
  sportId: string | undefined,
  countryId: string | undefined,
  leagueId: string | undefined,
  page: number | undefined,
  pageSize: number | undefined
) => {
  const queryParams = [];
  if (sportId) queryParams.push(`sport=${sportId}`);
  if (countryId && countryId !== '-1') queryParams.push(`country=${countryId}`);
  if (leagueId) queryParams.push(`league=${leagueId}`);
  if (page) queryParams.push(`page=${page}`);
  if (pageSize) queryParams.push(`page_size=${pageSize}`);
  const queryString = queryParams.length ? `?${queryParams.join('&')}` : '';
  const response = await axios.get(`/api/v1/sport/matches${queryString}`);
  return response.data;
};

export const getMatch = async (keyId: any) => {
  const response = await axios.get(`/api/v1/sport/matche/${keyId}`);
  return response.data;
};
