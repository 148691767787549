import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';

import { paths } from 'src/routes/paths';
import { usePathname } from 'src/routes/hooks';

import { useResponsive } from 'src/hooks/use-responsive';
import { useMockedUser } from 'src/hooks/use-mocked-user';

import { useTranslate } from 'src/locales';

import Logo from 'src/components/logo';
import Scrollbar from 'src/components/scrollbar';

import AppSidebar from 'src/sections/overview/sport/app-sidebar';
import AppGames from 'src/sections/overview/casino/app-game-list';
import AppTopCompetition from 'src/sections/overview/sport/app-top-competition';

import { Tabs, Tab, InputBase, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { NAV } from '../config-layout';
import NavToggleButton from '../common/nav-toggle-button';

// ----------------------------------------------------------------------

type Props = {
  openNav: boolean;
  onCloseNav: VoidFunction;
};

export default function NavVertical({ openNav, onCloseNav }: Props) {
  const { user } = useMockedUser();
  const { t } = useTranslate();

  const pathname = usePathname();

  const isOnCasinoPage = pathname === paths.casino.root || pathname === `${paths.casino.root}/`;

  const lgUp = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState(0);
  const [code, setCode] = useState('');
  const [searchMatch, setSearchMatch] = useState('');

  const handleClick = () => {
    navigate(paths.betcode.view(code));
  };

  const handleSearchMatch = () => {
    navigate(paths.sport.search_match(searchMatch));
  };

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Logo sx={{ mt: 3, ml: 4, mb: 1 }} />
      <Box sx={{ px: 2 }}>
        <Tabs
          value={tabIndex}
          onChange={(e, newValue) => setTabIndex(newValue)}
          variant="fullWidth"
          sx={{ mb: 2 }}
        >
          <Tab
            label="Betcode"
            sx={{
              fontWeight: 'bold',
              textTransform: 'none',
              fontSize: 16,
            }}
          />
          <Tab
            label="Recherche"
            sx={{
              fontWeight: 'bold',
              textTransform: 'none',
              fontSize: 16,
            }}
          />
        </Tabs>

        {tabIndex === 0 && (
          <Box sx={{ display: 'flex', gap: 1 }}>
            <InputBase
              fullWidth
              placeholder="Saisissez le Betcode"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              endAdornment={
                <Button
                  onClick={handleClick}
                  disabled={!code}
                  color="warning"
                  variant="contained"
                  size="small"
                  sx={{ mr: 0.5 }}
                >
                  Valider
                </Button>
              }
              sx={{
                pl: 1.5,
                height: 40,
                borderRadius: 1,
                bgcolor: 'common.white',
                boxShadow: 1,
              }}
            />
          </Box>
        )}

        {tabIndex === 1 && (
          <Box sx={{ display: 'flex', gap: 1 }}>
            <InputBase
              fullWidth
              placeholder="Recherche"
              value={searchMatch}
              onChange={(e) => setSearchMatch(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && searchMatch) {
                  handleSearchMatch();
                }
              }}
              endAdornment={
                <Button
                  onClick={handleSearchMatch}
                  disabled={!searchMatch}
                  color="primary"
                  variant="contained"
                  size="small"
                  sx={{ mr: 0.5 }}
                >
                  Chercher
                </Button>
              }
              sx={{
                pl: 1.5,
                height: 40,
                borderRadius: 1,
                bgcolor: 'common.white',
                boxShadow: 1,
              }}
            />
          </Box>
        )}
      </Box>

      {isOnCasinoPage ? (
        <AppGames title="Jeux" sx={{ py: 3 }} />
      ) : (
        <>
          <AppTopCompetition title={t('layout.top_competition')} sx={{ py: 3 }} />
          <AppSidebar title={t('layout.top_country')} />
        </>
      )}
    </Scrollbar>
  );

  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_VERTICAL },
      }}
    >
      <NavToggleButton />

      {lgUp ? (
        <Stack
          sx={{
            height: 1,
            position: 'fixed',
            width: NAV.W_VERTICAL,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
        </Stack>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.W_VERTICAL,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
